import React from 'react';
import Image from 'next/image';
import SectionHeading from '../Common/SectionHeading';

const AdvantagesSection = ({title=null}) => {
  return (
    <section id="advantages" className="">
        <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:py-8 py-4">
            <p className='mb-0 text-center'>Partners & Supporters</p>
            <SectionHeading title={title}  customClass="text-center"/>
            <div className="grid grid-cols-12 gap-4 mt-2">
                <div className="lg:col-span-4 col-span-12 text-center">
                    <div className="text-center">
                        <Image 
                            priority={false} 
                            width={100} 
                            height={100} 
                            className="text-center mx-auto" 
                            src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Buy-now-and-pay-later-(Partners-&-Supporters).png'}
                            alt="fancybox"
                        />
                    </div>
                    <h3 className="text-xl font-semibold">Buy Now and Pay Later</h3>
                    <p className="text-base">
                        We provide our clients with flexible payment options for our
                        reports, such as buy now and pay later and add-on reports on
                        credit.
                    </p>
                </div>
                <div className="lg:col-span-4 col-span-12 text-center">
                    <div className="text-center">
                        <Image 
                            priority={false} 
                            width={100} 
                            height={100} 
                            className="text-center mx-auto" 
                            src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Customizable-Dashboard-dark-(Partners-&-Supporters).png'}
                            alt="fancybox"
                        />
                    </div>
                    <h3 className="text-xl font-semibold">Customizable Dashboard</h3>
                    <p className="text-base">
                        A client-oriented window tailored to their requirements. The
                        provided dynamic excel model facilitates data manipulation and
                        simulation.
                    </p>
                </div>
                <div className="lg:col-span-4 col-span-12 text-center">
                    <div className="text-center">
                        <Image 
                            priority={false} 
                            width={100} 
                            height={100} 
                            className="text-center mx-auto" 
                            src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/24x7-Analyst-Support-dark(Partners-&-Supporters).png'}
                            alt="fancybox"
                        />
                    </div>
                    <h3 className="text-xl font-semibold">24*7 Analyst Support</h3>
                    <p className="text-base">
                        We assign one analyst per account for real-time analyst support
                        to provide analytical assistance to our clients.
                    </p>
                </div>
            </div>
        </div>
    </section>
  );
};

export default AdvantagesSection;